import createPersistedState from "vuex-persistedstate";
import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/userInfo.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {
    userInfo: state => state.user.userInfo, // 用户信息
    isStudent: state => state.user.isStudent,
    isLoading: state => state.user.isLoading,
    token: state => state.user.token,
    aijyToken: state => state.user.aijyToken,
    userId: state => state.user.userId,
  },
  mutations: {},
  actions: {},
  modules: {
    user
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});