import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/reset.scss";
import "./assets/scss/common.scss";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import api from "./api/enter.js"
import moment from 'moment'
Vue.prototype.$moment = moment
const Base64 = require('js-base64').Base64;
Vue.prototype.Base64 = Base64

// 定义一个全局过滤器实现日期格式化
Vue.filter('datrfmt', function (input, fmtstring) {
  if (!input) {
    return '---'
  }
  return moment(input).format(fmtstring);
})
Vue.use(ElementUI);
Vue.prototype.$api = api

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");