<template>
  <div>
    <screen-adapter
      :top="48"
      @setScale="setScaleHandler"
      v-if="$route.name != 'app_download_ios_check'"
    >
      <div id="app">
        <router-view></router-view>
      </div>
    </screen-adapter>
    <div v-else>
      <router-view></router-view>
    </div>
    <bulletin></bulletin>
    <div class="help_center" v-if="$route.name != 'app_download_ios_check'">
      <div class="help_item mb_down_item" @click="goMbDown()">
        <!-- <div class="help_icon"></div>
        <p class="help_tit">下载移动端</p> -->
      </div>
      <div class="help_item">
        <div class="help_icon"></div>
        <p class="help_tit">电话客服</p>
        <div class="item_tip">
          <p>
            <img
              src="https://downloads.whzhjy.cn/desktop/image/help_center_1.png"
              alt=""
            />
            <span>电话客服</span>
          </p>
          <p>周一至周日：<span>8:30～22:00</span></p>
          <p>4001-009-595</p>
        </div>
      </div>
      <div class="help_item" @click="kf_show = !kf_show">
        <div class="help_icon"></div>
        <p class="help_tit">在线客服</p>
      </div>
      <div class="help_item">
        <div class="help_icon"></div>
        <p class="help_tit">举报热线</p>
        <div class="item_tip">
          <p>
            <img
              src="https://downloads.whzhjy.cn/desktop/image/help_center_3.png"
              alt=""
            />
            <span>举报热线</span>
          </p>
          <p>周一至周日：<span>8:30～22:00</span></p>
          <p>4001-009-595</p>
        </div>
      </div>
      <div class="help_item go_top" @click="goTop">
        <i class="el-icon-arrow-up help_tit"></i>
        <p class="help_tit">TOP</p>
        <p class="help_tit">回到顶部</p>
      </div>
    </div>
    <div class="line_kf" v-show="kf_show">
      <i class="el-icon-close" @click="kf_show = false"></i>
      <iframe
        src="https://xffk.iflytek.com/pc/#/?configId=1610530810369&amp;customerId=&amp;falg=true&amp;callback=callbackName"
        frameborder="0"
        allow="microphone;camera;"
      ></iframe>
    </div>
  </div>
  <!-- :style="getWrapStyle" -->
  <!--  v-loading="isLoading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" -->
</template>

<script>
import ScreenAdapter from "./components/screen-adapter.vue";
import bulletin from "./components/bulletin.vue";
// 公用方法
import { loadScript } from "./utils/common.js";
import { mapGetters } from "vuex";
export default {
  components: { ScreenAdapter, bulletin },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  data() {
    return {
      kf_show: false,
    };
  },
  created() {
    this.getPublicNav();
  },
  mounted() {
    this.initBigDataLog();
  },
  methods: {
    // 获取导航头
    getPublicNav() {
      // 地址需添加时间戳 防止浏览器使用缓存导致导航头不展示
      // 生产模式根据当前部署地址判断 本地地址固定
      loadScript(
        `${
          process.env.NODE_ENV !== "production"
            ? "https://wuhutestbasic.changyan.cn"
            : `${location.origin}`
        }/ebgnavigation/ebgnavigation.js?v=${new Date().getTime()}`,
        () => {
          window.ebgnavigation && window.ebgnavigation.init();
          ebgnavigation.on(
            "getNavbarConfigData",
            ({ user = {}, roleInfo = {}, identityList = [] }) => {
              console.log(user, "getNavbarConfigData");
              this.$store.commit("setUserInfo", user);
            }
          );
        }
      );
    },
    initBigDataLog() {
      this.$api.getIflyCollectorConfig().then((data) => {
        console.log(data, "埋点初始化");
        window.initBigdatalog(
          data.appId,
          data.host,
          data.debug,
          data.spa,
          data.configUrl,
          data.collectUrl,
          data.source
        );
      });
    },
    setScaleHandler(scale) {
      console.log(scale);
    },
    goMbDown() {
      const router = this.$router.resolve({
        path: "/appDownload",
      });
      window.open(router.href, "_blank");
    },
    goTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  display: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  overflow-x: scroll;
  width: 1920px;
  // overflow-x: hidden;
  // overflow-y: auto;
}
.link_color {
  color: #368fff;
}
.help_center {
  position: fixed;
  font-size: 14px;
  top: 70%;
  transform: translateY(-50%);
  right: 29px;
  z-index: 9998;
  .help_item {
    width: 60px;
    height: 60px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e2e9f2;
    margin-bottom: 16px;
    font-size: 12px;
    color: #595959;
    text-align: center;
    cursor: pointer;
    position: relative;
    &:nth-of-type(1) {
      font-size: 11px;
      .help_icon {
        margin-bottom: 8px;
      }
    }
    .help_icon {
      width: 100%;
      height: 34px;
      margin-bottom: 5px;
      background: url("./assets/image/help_center_0.png") no-repeat bottom center;
      background-size: 26px !important;
    }
    &:nth-of-type(2) {
      .help_icon {
        background: url("https://downloads.whzhjy.cn/desktop/image/help_center_1.png")
          no-repeat bottom center;
      }
    }
    &:nth-of-type(3) {
      .help_icon {
        background: url("https://downloads.whzhjy.cn/desktop/image/help_center_2.png")
          no-repeat bottom center;
      }
    }
    &:nth-of-type(4) {
      .help_icon {
        background: url("https://downloads.whzhjy.cn/desktop/image/help_center_3.png")
          no-repeat bottom center;
      }
    }
  }
  .help_item:hover:not(.mb_down_item) {
    background: #368fff;
    box-shadow: 0px 0px 16px 0px rgba(54, 143, 255, 0.2);
    border-color: #368fff;
    .item_tip {
      display: block;
    }
    .help_tit {
      color: #fff;
    }
    &:nth-of-type(2) {
      .help_icon {
        background: url("https://downloads.whzhjy.cn/desktop/image/help_center_1_hv.png")
          no-repeat bottom center;
      }
    }
    &:nth-of-type(3) {
      .help_icon {
        background: url("https://downloads.whzhjy.cn/desktop/image/help_center_2_hv.png")
          no-repeat bottom center;
      }
    }
    &:nth-of-type(4) {
      .help_icon {
        background: url("https://downloads.whzhjy.cn/desktop/image/help_center_3_hv.png")
          no-repeat bottom center;
      }
    }
  }
  .mb_down_item {
    background: url("./assets/image/downLoad_hover.png") no-repeat center;
    border: 1px solid #c3d9f5;
    background-size: 62px;
  }
  .mb_down_item:hover {
    opacity: 0.8;
  }
  .go_top {
    i {
      padding-top: 6px;
      font-size: 16px;
      color: #262626;
      font-weight: bold;
    }
    p:nth-of-type(1) {
      padding: 2px 0 3px;
    }
  }
  .item_tip {
    position: absolute;
    top: 0;
    right: 70px;
    width: 229px;
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(77, 100, 128, 0.12);
    padding: 11px 16px;
    text-align: left;
    color: #262626;
    font-size: 14px;
    display: none;
    border-radius: 4px;
    p:nth-of-type(1) {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
    }
    p:nth-of-type(2) {
      padding: 16px 0;
      color: #8c8c8c;
      span {
        color: #262626;
      }
    }
    p:nth-of-type(3) {
      font-size: 18px;
      color: #368fff;
    }
  }
}
.line_kf {
  position: fixed;
  right: 100px;
  bottom: 0;
  box-shadow: 0px 0px 10px 0;
  width: 580px;
  height: 460px;
  border-radius: 25px;
  overflow: hidden;
  z-index: 9999;
  iframe {
    width: 100%;
    height: 100%;
  }
  i {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #dcdfe6;
    cursor: pointer;
  }
}
</style>
