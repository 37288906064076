/** @format */

const crypto = require('crypto-js');
const Base64 = require('js-base64').Base64;
// const StringBuilder = require('string-builder')
/**
 * 签名工具
 * @author ffzhen
 * @class SignUtils
 */
export class SignUtils {
  /**
   * hmacsha256签名计算
   *
   * @param secret 签名密钥
   * @param url 请求完整路径
   * @param authNonce 随机数
   * @param authTimestamp 时间戳
   * @param authAppId appid
   * @param contentMD5 请求体md5
   * @return signature 签名
   */
  static sign(secret, url, authNonce, authTimestamp, authAppId, contentMD5) {
    try {
      const values = [];
      values[0] = url;
      values[1] = authNonce;
      values[2] = authTimestamp;
      values[3] = authAppId;
      values[4] = contentMD5;
      const stringToSign = SignUtils.buildStringToSign(values);

      // // const signature = crypto.createHmac('sha256', secret).update(stringToSign).digest().toString('base64')
      // Base64.extendString()
      // console.log(crypto.HmacSHA256(stringToSign, secret).toString());
      // console.log(Base64.encode(crypto.HmacSHA256(stringToSign, secret).toString()));
      // // const signature = crypto.HmacSHA256(stringToSign, secret).toString().toBase64();
      // const signature = Base64.encode(crypto.HmacSHA256(stringToSign, secret).toString())
      const hash = crypto.HmacSHA256(stringToSign, secret);
      const signature = crypto.enc.Base64.stringify(hash);
      return signature;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * 根据请求头部元素构建签名字段
   *
   * @static
   * @param {any} headValues 头部信息数组
   * @returns stringToSign
   * @memberOf SignUtils
   */
  static buildStringToSign(headValues) {
    let sb = '';
    for (let i = 0; i < headValues.length; i++) {
      if (headValues[i] != '' && headValues[i] != undefined && headValues[i] != null) {
        // 如果为空则跳过
        sb += headValues[i] + SignUtils.SEPARATOR;
      }
    }
    const stringToSign = sb.toString().substring(0, sb.toString().lastIndexOf(SignUtils.SEPARATOR));
    // 形如/flylog/lbtest|15bfe92e-a774-44af-baee-c66fb696668b|1517390150487|686a3851854e4e9aa9b2420f1681fdc4
    return stringToSign;
  }

  // static buildAuthRandomKey(publicKey,rondomKey){
  //     let buffer = new Buffer(publicKey,'hex');
  //     let base64Buffer = buffer.toString('base64');
  //     let publicKeyStr = "-----BEGIN PUBLIC KEY-----\n"+base64Buffer+"\n-----END PUBLIC KEY-----";
  //     const key = new NodeRSA(publicKeyStr);
  //     key.setOptions({encryptionScheme:'pkcs1'})
  //     return key.encrypt(rondomKey,'base64','utf8')
  // }
}
// 分隔符
SignUtils.SEPARATOR = '|';

export default SignUtils;