// 创建一个script标签
export function loadScript (url, callback) {
  const script = document.createElement('script'),
    head = document.getElementsByTagName('head')[0];
  script.type = 'text/javascript';
  script.src = url;
  if (callback) {
    if (script.addEventListener) {
      script.addEventListener('load', function () {
        callback();
      }, false);
    } else if (script.attachEvent) {
      script.attachEvent('onreadystatechange', function () {
        const target = window.event.srcElement;
        if (target.readyState == 'loaded') {
          callback();
        }
      });
    }
  }
  head.appendChild(script);
}

// 给数字补逗号
export function NumberAddComma(number) {
  if (number === '' || number === undefined || number === null) {
    return ''
  }
  if (!Number(number)) {
    return number
  }
  // 加入,  不改变原本保留的小数位数 (超过21位会四舍五入 理论上不会超过)
  return Number(number).toLocaleString('en',{ style:'decimal', maximumFractionDigits: 20 })
}

// 简单深拷贝 可处理属性值中不包含函数的引用类型深拷贝
export function forClone(item) {
  return JSON.parse(JSON.stringify(item))
}