import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import api from '../api/api.js'
// /desktop/backend/login/sso/config

Vue.use(VueRouter);

const router = new VueRouter({
  // mode: 'history',
  routes: [{
      path: '/',
      name: 'home',
      meta: {
        auth: false
      },
      component: () => import('../views/home.vue'),
    },
    {
      path: '/bindUser',
      name: 'bindUser',
      meta: {
        auth: false
      },
      component: () => import('../views/bindUser.vue'),
    },
    {
      path: '/wxBind',
      name: 'wxBind',
      meta: {
        auth: true
      },
      component: () => import('../views/wxBind.vue'),
    },
    {
      path: '/appDownload',
      name: 'appDownload',
      meta: {
        auth: false
      },
      component: () => import('../views/app_download.vue'),
    },
    {
      path: '/app_download_ios_check',
      name: 'app_download_ios_check',
      meta: {
        auth: false
      },
      component: () => import('../views/app_download_ios_check.vue'),
    },
    {
      path: '*',
      redirect: to => {
        return {
          name: 'home'
        }
      }
    }
  ],
  // mode: 'hash',
  // base: process.env.BASE_URL
});
const initSSOConfig = (res) => {
  if (SSO) {
    // SSO.needToken = true;
    // //免密登录方式，指定用户ID、第三方平台Key、第三方平台Secret、内部获取Token地址
    // //TODO 根据实际客户端类型填写
    // SSO.client = "web";
    // //TODO 根据实际应用使用的appKey标识填写
    // SSO.appKey = process.env.VUE_APP_SSO_appKey;
    // SSO.mac = SSO.deviceId;
    // SSO.customLogoutUrl = "";
    // SSO.needAttribute = true;
    // SSO.captchaType = 'third';
    // SSO.appId = process.env.VUE_APP_SSO_appId;
    // //************** 登录信息配置项结束 ******************************

    // //********************* SSO配置项开始 ****************************
    // SSO.SSOServerUrl = process.env.VUE_APP_SSO_URL;
    // SSO.SSOCenterServerUrl = process.env.VUE_APP_SSO_CEN_URL
    // //应用地址
    // SSO.loginRedirectUrl = "";
    // SSO.isCenterSSO = true;
    // SSO.accountTypeIsAuto = true;
    // //三方验证码类型
    // SSO.thirdCaptchaType= "third";
    //   //图形验证码类型
    // SSO.picCaptchaType="pic";
    // SSO.gtApiServers=process.env.VUE_APP_SSO_BASE_URL;
    // SSO.gtStaticServers=process.env.VUE_APP_SSO_BASE_URL;
    // SSO.gtProtocol="https://";
    // SSO.accountLoginVersion="v2";

    // new
    SSO.appName = res['xf.sso.appid'];
    SSO.appKey = res['xf.sso.ssoAppKey'];
    SSO.SSOServerUrl = res['sso.server'];
    SSO.SSOCenterServerUrl = res['sso.center.server'];
    if (res['sso.isCenterSSO'] !== undefined) {
      SSO.isCenterSSO = res['sso.isCenterSSO'];
    }
    if (res['sso.syncType'] !== undefined) {
      SSO.syncType = res['sso.syncType'];
    }
  }
}
// 获取登录信息
const getLoginState = () => {
  return new Promise((resolve, reject) => {
    SSO.getLoginState(resultObj => {
      if (resultObj.code === 1001) {
        resolve(resultObj)
      } else {
        reject(resultObj)
      }
    })
  })
}
const getToken = () => {
  return new Promise((resolve, reject) => {
    SSO.getAccessToken(function (resultObj) {
      if (resultObj.code === '1') {
        resolve(resultObj)
      } else {
        reject(resultObj)
      }
    })
  })
}

//以下代码解决路由地址重复点击的报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

async function routerBeforeEachFunc(to, from, next) {
  //获取用户信息登录态
  console.log(to.meta)
  api.getSSOConfig().then(res => {
    console.log(res.data, '-------------')
    if (res.data) {
      initSSOConfig(res.data);
      // SSO.SSOServerUrl = 'https://test-open.changyan.com/sso'
      getLoginState().then((state) => {
        store.commit('setIsLogin', true);
        getToken().then(token => {
          store.commit('setToken', token.data.token);
          store.commit('setLoginName', token.data.loginName);
          store.commit('setUserId', token.data.userId);
          sessionStorage.setItem('token', token.data.token);
          sessionStorage.setItem('userId', token.data.userId);
          next()
        })
      }).catch(err => {
        $.ajax({
          method: 'GET',
          url: `${window.location.origin === 'https://whzhjy.cn' ? 'https://open.changyan.com/sso' : 'https://test-open.changyan.com/sso'}` + '/login',
          dataType: 'jsonp',
          success: function (data) {
            JSON.parse(data).code == 1001 ? store.commit('setIsLogin', true) : store.commit('setIsLogin', false)
            getToken().then(token => {
              store.commit('setToken', token.data.token);
              store.commit('setLoginName', token.data.loginName);
              store.commit('setUserId', token.data.userId);
              sessionStorage.setItem('token', token.data.token);
              sessionStorage.setItem('userId', token.data.userId);
              next()
            })
          }
        })
        // window.location.href = location.origin;
      })
    }
  }).catch(err => {
    console.log(err)
  })
  if (!to.meta.auth) {
    next()
  }
}
// 全局路由拦截器
router.beforeEach(routerBeforeEachFunc)
export default router;