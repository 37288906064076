import request from '../utils/request.js'

export default {
  getIflyCollectorConfig() {
    return request({
      method: "post",
      url: "/desktop/backend/get/iflyCollectorConfig",
    }).then((res) => {
      return Promise.resolve(res.data);
    });
    // return http.post("/get/iflyCollectorConfig").then((result) => {
    //   sessionStorage.setItem("BIG_DATA_BOGGER", JSON.stringify(result));
    //   return Promise.resolve(result);
    // });
  }
}