<template>
  <div class="bulletin" v-if="isContent">
    <div class="ad">
      <i class="el-icon-bell"></i>
      <p class="content">
        <span ref="ad_cnt">{{ msg }}</span>
      </p>
      <i class="el-icon-circle-close" v-if="isShowClose" @click="isContent = false"></i>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "bulletin",
  props: {},
  components: {},
  data() {
    return {
      msg: "",
      isContent: false,
      isShowClose: false,
    };
  },
  computed: mapState({}),
  mounted() {
    // this.$refs.ad_cnt.style.animationDuration = `${60}s`
    this.getShowNotice();
  },
  methods: {
    getShowNotice() {
      this.$api
        .getShowNotice()
        .then((res) => {
          if (res.flag && res.data) {
            this.msg = res.data.content;
            res.data.switchAllow === "1"
              ? (this.isShowClose = true)
              : (this.isShowClose = false);
            this.isContent = true;
          }
        })
        .catch((err) => {
          this.$message.error("公告返回数据错误");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.ad {
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  z-index: 9;
  height: 60px;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color: #353535;
  i {
    color: #ff6146;
    font-size: 25px;
    margin-right: 10px;
    &:last-child {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .content {
    flex: 1;
    overflow: hidden;
    span {
      font-size: 20px;
      color: red;
      display: block;
      width: auto;
      white-space: nowrap;
    }
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.content {
  span {
    display: block;
    width: auto;
    white-space: nowrap;
    animation: marquee 30s linear infinite;
    padding-left: 100%;
    padding-right: 100%;
    &:hover {
      animation-play-state: paused;
    }
  }
}
</style>
