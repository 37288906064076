<!-- @format -->
<template>
  <div id="screen-adapter" :style="style">
    <slot />
  </div>
</template>
<script>
export default {
  name: "",
  props: {
    width: {
      type: String,
      default: "1920",
    },
    height: {
      type: String,
      default: "1080",
    },
    top: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      style: {
        width: "100vw",
        height: 0 + "px",
        transformOrigin: "0 0",
        transform: "scale(1, 1)",
        top: this.top + "px",
        zoom: 1,
      },
    };
  },
  computed: {
    isIEBrowser() {
      return window.navigator.userAgent.indexOf("Trident/") !== -1;
    },
    isEdgeBrowser() {
      return window.navigator.userAgent.indexOf("Edge/") !== -1;
    },
  },
  mounted() {
    this.setScale();
    window.onresize = this.Debounce(this.setScale, 500);
  },
  methods: {
    Debounce: (fn, t) => {
      const delay = t || 500;
      let timer;
      return function () {
        const args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        const context = this;
        timer = setTimeout(() => {
          timer = null;
          fn.apply(context, args);
        }, delay);
      };
    },
    // 获取放大缩小比例
    getScale() {
      const w = window.innerWidth / +this.width;
      return w;
    },
    // 设置比例
    setScale() {
      const scale = this.getScale();
      if (this.isEdgeBrowser) {
        this.style.zoom = scale;
        delete this.style.transform;
        delete this.style.transformOrigin;
      } else {
        this.style.transform = `scaleX(${scale}) scaleY(${scale})`;
        delete this.style.zoom;
      }
      this.$emit("setScale", scale);
    },
  },
};
</script>
<style lang="scss" scoped>
#screen-adapter {
  position: absolute;
  transition: 0.2s;
  width: 100vw;
  height: 0;
  position: absolute;
  left: 0;
  right: 0;
  box-sizing: border-box;
}
</style>
