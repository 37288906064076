import axios from "axios";
import Qs from "qs";
import {
  Loading,
  Message
} from "element-ui";
import store from '../store'
import SignUtils from '../api/signUtils'
const request = axios.create({
  baseURL: '',
  timeout: 150000
  // application/json
  // application/x-www-form-urlencoded
});
const setAijyConfig = function (url) {
  const appId = '4b7eee5b4229484c88d78d15036558c9'
  const signature = SignUtils.sign(
    '52B9EB0F9DE3633D7FDAC30E63E9D8E7',
    url,
    '',
    new Date().valueOf(),
    appId,
    ''
  );
  return {
    signature,
    appId
  }
}

// let loading;

// function startShowLoading() {
//   loading = Loading.service({
//     lock: true,
//     text: "加载中...",
//     background: "rgba(0,0,0,.7)",
//   });
// }

// 添加请求拦截器
request.interceptors.request.use(function (config) {
  if (config.type === 'aijy') {
    let {
      signature,
      appId
    } = setAijyConfig(config.url)
    config.headers["S-Auth-Signature"] = signature
    config.headers["S-AUTH-AppId"] = appId
    // config.url = `https://epasapi.${window.location.host == 'whzhjy.cn' ? 'whzhjy.cn' : 'wuhutestbasic.changyan.cn'}${config.url}`
    config.url = `${process.env.VUE_APP_AIJY_BASE_URL}${config.url}`
    store.getters.aijyToken ? config.headers["S-AUTH-Token"] = store.getters.aijyToken : ''
    store.getters.userId ? config.headers["S-Token-UserId"] = store.getters.userId : ''
  }
  // config.headers["appkey"] = process.env.VUE_APP_SSO_appId;
  // let authToken = store.getters.token;
  // authToken && (config.headers["token"] = authToken);
  store.commit('setIsLoading', true);
  if (config.isQS) {
    config.data = Qs.stringify(config.data)
  }
  if (config.method === "get") {
    if (config.params) {
      config.params.currentTime = Date.parse(new Date());
    } else {
      config.params = {
        currentTime: Date.parse(new Date()),
      };
    }
  }

  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  store.commit('setIsLoading', false);
  // 对响应数据做点什么
  if (response.data.flag || response.data.status) {
    return response.data
  } else {
    return response
  }
}, function (error) {
  store.commit('setIsLoading', false);
  console.log(error)
  Message.error("请求响应失败")
  // 对响应错误做点什么
  return Promise.reject(error)
  // return Promise.resolve({
  //   name: '张三',
  //   age: 19
  // });
});

export default request