import request from '../utils/request.js'
const baseUrl = '/basicDataManage/backend'
// const baseUrl2 = window.location.origin === 'https://wuhutestbasic.changyan.cn' ? '/qxpt_third' : ''
const baseUrl2 = window.location.host != 'whzhjy.cn' ? '/qxpt_third' : ''
// /backend
export default {
  // 推荐模块应用
  getAllModuleNames() {
    return request({
      method: "get",
      url: baseUrl + "/moduleName/getAllModuleNames",
    })
  },
  // 飘窗
  getShowWaveWindowList() {
    return request({
      method: "get",
      url: baseUrl + "/waveWindowManage/getShowWaveWindowList",
    })
  },
  // 维护公告
  getShowNotice() {
    return request({
      method: "get",
      url: baseUrl + "/noticeHomePageManage/getShowNotice",
    })
  },
  // 获取所有模块数据
  // 生产包不带项目名qxpt_third
  getIndexAllData() {
    return request({
      method: "get",
      url: baseUrl2 + "/manage/loginData_getIndexAllData.action",
    })
  },
  // 获取行业动态
  // 生产包不带项目名qxpt_third
  getZfxwNews() {
    return request({
      method: "get",
      url: baseUrl2 + "/manage/loginData_getZfxwNews.action",
    })
  },
  getJyzxDataByType(params) {
    return request({
      method: "get",
      url: baseUrl2 + `/manage/loginData_getJyzxDataByType.action?type=${params.type}`,
    })
  },
  // ios下载请求验证码
  getSmsCodeIosDownload(params) {
    return request({
      method: "get",
      url: baseUrl2 + `/manage/login_getSmsCodeIosDownload.action?phone=${params}`
    })
  },
  // ios验证码校验
  validateIosDownload(params) {
    return request({
      method: "get",
      url: baseUrl2 + `/manage/login_validateIosDownload.action?phone=${params.phone}&smsCode=${params.code}`
    })
  },
  getSSOConfig() {
    return request({
      method: "get",
      url: "/desktop/backend/login/sso/config",
    })
  },
  encryptionBeforre() {
    return request({
      method: "get",
      url: "/desktop/backend/password/encryptionBeforre",
    })
  },
  thirdPartyBind(data) {
    return request({
      method: "get",
      url: "/desktop/backend/bind/thirdPartyBind",
      params: data
    })
  },
  queryUserBindInfo(data) {
    return request({
      method: "get",
      url: "/desktop/backend/bind/queryUserBindInfo",
      params: data
    })
  },
  unBindUser(data) {
    return request({
      method: "get",
      url: "/desktop/backend/bind/unBindUser",
      params: data
    })
  },
  getAppId(data) {
    return request({
      method: "get",
      url: `/appSupervise/backend/whZhgl/getAppId?appName=${data}`,
    })
  },
  // getJyzx() {
  //   return request({
  //     method: "get",
  //     url: "https://whzhjy.cn/information/show/news/getNewsFromModule.do?moduleCode=shouye&columnCode=TPXW&page=1&pageSize=9",
  //   })
  // },
  // getBmyw() {
  //   return request({
  //     method: "get",
  //     url: "https://whzhjy.cn/information/show/news/getNewsListForJygg.do?columnCode=BMYW&page=1&limit=9",
  //   })
  // },
  // getHydt() {
  //   return request({
  //     method: "get",
  //     url: "https://whzhjy.cn/information/show/news/getNewsListForJygg.do?columnCode=HYDT&page=1&limit=9",
  //   })
  // },
  getAijyData(type) {
    return request({
      method: "get",
      url: `/teachingResearch/areaEvaluateActivity/getFirstPageAreaEvaluateList?type=${type}`,
      type: 'aijy'
    })
  },
  getZxzbData(type) {
    return request({
      method: "get",
      url: `/teachingResearch/researchOnSiteWeb/homePageList?type=${type}`,
      type: 'aijy'
    })
  },
  getJspxData() {
    return request({
      method: "get",
      url: `/teachingResearch/trainingPlan/home/getHomePageTrainingPlanList`,
      type: 'aijy'
    })
  },
  signUpTrainingPlan(params) {
    return request({
      method: "post",
      url: `/teachingResearch/trainingPlan/signUpTrainingPlan`,
      type: 'aijy',
      data: params
    })
  },
  userSign(params) {
    return request({
      method: "post",
      url: `/teachingResearch/project/userSign`,
      type: 'aijy',
      data: params
    })
  },
  listTrainingPlanOfflineToos(params) {
    return request({
      method: "get",
      url: `/teachingResearch/trainingPlan/listTrainingPlanOfflineToos`,
      type: 'aijy',
      params
    })
  },
  getLiveAppSecret() {
    return request({
      method: "get",
      url: `/teachingResearch/live/getLiveAppSecret`,
      type: 'aijy'
    })
  },
  homePageNoticeList() {
    return request({
      method: "get",
      url: `/teachingResearch/researchSubjectHomePage/homePageNoticeList?pageSize=4`,
      type: 'aijy'
    })
  },
  getLiveRoomStatus(params) {
    return request({
      method: "get",
      url: `/teachingResearch/researchOnSiteWeb/getLiveRoomStatus`,
      type: 'aijy',
      params
    })
  },
  callBackLiveStatus(params) {
    return request({
      method: "post",
      url: `/teachingResearch/researchOnSiteWeb/callBackLiveStatus`,
      type: 'aijy',
      data: params
    })
  },
  aijyJump(id) {
    return request({
      method: "get",
      url: `/teachingResearch/areaEvaluateActivity/getUserRoleListInActivity?activityId=${id}`,
      type: 'aijy'
    })
  },
  // 智慧课堂达标数据
  appStatistics2(params) {
    return request({
      method: "get",
      url: `/desktop-api/index/appStatistics2`
    })
  },
  // 一卡通权限
  openYKT() {
    return request({
      method: "get",
      url: `/desktop/backend/indexPage/appUrl?appId=8effbcd1639a47018b6e7488d729911d`
    })
  },
}